import React, { HTMLAttributes } from 'react';
import { PropsWithTestId } from '../../../../../../types';
import {
  CardAnchor,
  Badge,
  Image,
  ImageProps,
  Title,
  TitleProps,
} from '../components';
import { ProductCard } from '../../products';
import { DiscoveryBrandSpeaker } from '../../../../../../store/features/discovery';
import styles from './SpeakerCard.module.scss';

export interface SpeakerCardProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  data: DiscoveryBrandSpeaker;
  url: string;
}

export default function SpeakerCard(props: PropsWithTestId<SpeakerCardProps>) {
  const {
    data: { title, photo, jobTitle, company },
    testId,
    url,
  } = props;

  const getImage = (style?: ImageProps['imageStyle']) => (
    <Image imageStyle={style} path={photo} />
  );

  const getCompany = () =>
    company && (
      <Badge
        testId="speaker-card-company"
        size="tiny"
        className={styles.company}
      >
        {company}
      </Badge>
    );

  const getJobTitle = () =>
    jobTitle && (
      <Badge testId="speaker-card-job" className={styles.job}>
        {jobTitle}
      </Badge>
    );

  const getTitle = (
    size?: TitleProps['size'],
    className?: TitleProps['className'],
  ) => (
    <>
      <Title size={size} className={className}>
        {title}
      </Title>
      {getJobTitle()}
    </>
  );

  return (
    <CardAnchor to={url} data-testid={testId}>
      <ProductCard
        image={getImage()}
        title={getTitle('extraSmall')}
        leftFooter={getCompany()}
        className={styles.wrapper}
      />
      <div itemScope itemType="https://schema.org/Speaker">
        <meta property="og:title" content={title} />
        <meta property="og:job-title" content={jobTitle} />
        <meta property="og:path" content={url} />
      </div>
    </CardAnchor>
  );
}

SpeakerCard.defaultProps = {
  testId: 'speaker-card',
};
