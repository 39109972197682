import React from 'react';
import cn from 'classnames';
import { PropsWithTestId } from '../../../../../../types';
import { HubModuleWrapper } from '../../HubModuleWrapper';
import { SpeakersModuleType } from './types';
import { ModuleHeader } from '../../components';
import { CallToAction } from '../../../CallToActionGroup';
import SpeakerCard from '../../../productFilter/cards/SpeakerCard';
import s from './index.module.scss';

export interface SpeakersModuleProps {
  module: SpeakersModuleType;
}

export default function SpeakersModule(
  props: PropsWithTestId<SpeakersModuleProps>,
) {
  const {
    module: {
      '@type': type,
      title,
      subtitle,
      visualStyle,
      elementId,
      speakersFromDiscovery = [],
      link,
    },
    testId,
  } = props;

  return (
    <HubModuleWrapper
      type={type}
      theme={visualStyle}
      elementId={elementId}
      testId={testId}
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <ModuleHeader
              title={title}
              text={subtitle}
              lineAfterHeading={!!subtitle}
              className={s.headerOffset}
            />
          </div>
        </div>
        {speakersFromDiscovery.map((speakerBlock, index) => (
          <div className={s.wrapper} key={index}>
            <div className="row">
              <div className="col-xs-12">
                <ModuleHeader title={speakerBlock.siteName} lineAfterHeading />
              </div>
              {speakerBlock.speakers.map((speaker) => {
                const speakerType =
                  speakerBlock.contentType === 'EVENT'
                    ? 'speakers'
                    : 'trainers';
                const url = `${speakerBlock.sitePath}/${speakerType}/${speaker.path}`;

                return (
                  <div
                    className={cn('col-xs-12 col-md-3', s.speakerOffset)}
                    key={speaker.id}
                  >
                    <SpeakerCard data={speaker} url={url} />
                  </div>
                );
              })}
            </div>
          </div>
        ))}
        <div className="row">
          <div className="col-xs-12">
            {link?.type && (
              <CallToAction
                link={link}
                variant="contained"
                align="center"
                data-testid="hub-module-link"
              />
            )}
          </div>
        </div>
      </div>
    </HubModuleWrapper>
  );
}

SpeakersModule.defaultProps = {
  testId: 'hub-speakers-module',
};
